<template>
	<div class="content">
		<h1>File UpLoad</h1>
		<p><input type="file" v-on:change="fileSelected"></p>
		<button v-on:click="fileUpload">アップロード</button>
		<p v-show="showUserImage"><img v-bind:src="api_url+user.file_path"></p>
	</div>
</template>

<style>
.content{
	margin:5em;
}
</style>

<script>
export default {
	data: function(){
		return {
			fileInfo: '',
			user: '',
			showUserImage: false,
			api_url: this.$store.getters['param/api_url'],
		}
	},
	methods:{
		fileSelected(event){
			console.log(event);
			this.fileInfo = event.target.files[0];
		},
		fileUpload(){
			const formData = new FormData()
			formData.append('file',this.fileInfo)
			axios.post(this.api_url+'api/fileupload',formData)
			.then(response =>{
				console.log(response)
				this.user = response.data
				if(response.data.file_path) this.showUserImage = true
			});
		}
	}
}
</script>

