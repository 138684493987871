<template>
	<div
		v-if="photo"
		class="photo-detail"
		:class="{ 'photo-detail--column': fullWidth }"
	>
		<figure
			class="photo-detail__pane photo-detail__image"
			@click="fullWidth = ! fullWidth"
		>
			<img :src="photo.url" alt="">
			<figcaption>Posted by {{ photo.owner.name }}</figcaption>
		</figure>
		<div class="photo-detail__pane">

			<div class="card">
				<div class="card-header">PDF Settings</div>
					<div class="card-body">
					<div class="hello">
						{{ photo.id }} <br>
						{{ photo.cat1_id }} : 
						{{ photo.cat1.cat1name }}
					</div>

					<ul>
						<li v-for="cat1 in cat1s">{{ cat1.id }}-{{ cat1.cat1name }}</li>
					</ul>

					<div class="hello">
						<select v-model="pdf.cat1_id">
							<option v-for="cat1 in cat1s" v-bind:value="cat1.id">
								{{ cat1.cat1name }}
							</option>
						</select>
						<span>Selected: {{ pdf.cat1_id }}</span>
					</div>

				</div>
			</div>

		</div>
	</div>
</template>

<script>
import { OK } from '../util'
export default {
	components: {
	},
	props: {
		id: {
			type: String,
			required: true
		}
	},
	data () {
		return {
			pdf: {
				id: 1,
				cat1_id: 0,
			},
			fullWidth: false,
			photo: null,
			cat1s: [],
			api_url: this.$store.getters['param/api_url'],
		}
	},
	methods: {
    async fetchPhoto () {
			//console.log('mounted')
			axios.post(this.api_url+'api/test1',{
				content: 'あああああ',
				photoid: this.id,
			})
			.then(response =>{
				//console.log(response)
				if (response.status !== OK) {
					//console.log('ERROR')
					this.$store.commit('error/setCode', response.status)
					if(response.status == 401) {
						// トップページに移動する
						this.$router.push(this.api_url)
					}
					return false
				}
				this.cat1s = response.data.cat1s
				this.photo = response.data.photo
				this.pdf.cat1_id = this.photo.cat1_id
			});
    },
	},
	mounted() {
	},
  watch: {
    $route: {
      async handler () {
        await this.fetchPhoto()
      },
      immediate: true
    }
  },
	created () {
	},

}
</script>

