<template>
	<div v-if="isLogin" class="content">
		<h1>File UpLoadPond</h1>
		<file-pond 
			server='`/${api_url}api/fileuploadpond`'
			instantUpload="true"/>
	</div>
</template>

<script>
import { getCookieValue } from '../util'
//import { getMetaTag } from '../util'
import vueFilePond, { setOptions } from "vue-filepond";
import 'filepond/dist/filepond.min.css';
const FilePond = vueFilePond();

export default {
	data() {
		return {
			csrf_token: '',
			api_url: this.$store.getters['param/api_url'],
		}
	},
	mounted() {
		this.csrf_token = this.$store.getters['auth/csrf_token']
		//this.csrf_token = getMetaTag('csrf-token');
		console.log('mounted');
		console.log(this.csrf_token);
		setOptions({
			server: {
        url: this.api_url+'api/fileuploadpond',
				process: {
					headers: {
						//'X-Requested-With': 'XMLHttpRequest',
						//"X-XSRF-TOKEN": ((name)=>{var value = "; " + document.cookie;var parts = value.split("; " + name + "=");if (parts.length == 2)return parts.pop().split(";").shift()})("XSRF-TOKEN")
						//'X-XSRF-TOKEN': this.csrf_token,
						'X-CSRF-TOKEN': this.csrf_token,
						//'X-CSRF-TOKEN': csrf_token(),
					},
					onerror: res => {
						//debugger
						return '123'
					}
				}
			}
		})
	},
  computed: {
    isLogin () {
			console.log('isLogin');
      return this.$store.getters['auth/check']
    },
  },
	methods: {
	},
	components:{
		FilePond
	},
}
</script>

<style>
.content{
	margin:5em;
}
</style>
